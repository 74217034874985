import { NativeMap } from '@/utils/collections'
import { useAppContext } from '@/utils/hooks'
import {
	faChevronDown,
	faChevronLeft,
	faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled from 'styled-components'
import { MddWidgetModel } from '../../../utils'
import { GraphError } from '../../../validator'

type Props = {
	issues: GraphError[]
	onNodeFocus: (node: MddWidgetModel) => void
}

export const Issues = ({ issues, onNodeFocus: onZoom }: Props) => {
	const { t } = useAppContext()
	const [expanded, setExpanded] = useState({} as NativeMap<boolean>)

	const handleExpand = (e: React.MouseEvent, issue: GraphError) => {
		setExpanded({ ...expanded, [issue.id]: !expanded[issue.id] })
		e.stopPropagation()
		e.preventDefault()
	}

	return (
		<IssuesContainer>
			{issues.length > 0 && (
				<>
					<Title>
						<span>{t('ISSUES_TITLE')}</span>
						<Badge>
							<FontAwesomeIcon icon={faExclamationCircle} />
							<span>{issues.length}</span>
						</Badge>
					</Title>
					{issues.map(issue => {
						const isExpanded = expanded[issue.id]

						return (
							<Issue key={issue.id}>
								<IssueMain>
									<FontAwesomeIcon icon={faExclamationCircle} />
									{issue.node && (
										<IssueSource
											onClick={() => issue.node && onZoom(issue.node)}
										>
											{issue.node.getIssueName(t)}
										</IssueSource>
									)}
									<IssueMessage onClick={e => handleExpand(e, issue)}>
										{issue.message}
									</IssueMessage>
									<IssueMoreButton onClick={e => handleExpand(e, issue)}>
										<FontAwesomeIcon
											icon={isExpanded ? faChevronDown : faChevronLeft}
										/>
									</IssueMoreButton>
								</IssueMain>
								{isExpanded && <IssueMore>{issue.message}</IssueMore>}
							</Issue>
						)
					})}
				</>
			)}
		</IssuesContainer>
	)
}

const Title = styled.div`
	display: flex;
	background: rgba(240, 240, 240, 0.8);
	border-left: 1px solid #ccc;
	margin-bottom: 2px;
	align-items: center;

	> span {
		padding: 11px 8px;
	}
`

const Badge = styled.div`
	display: flex;
	align-items: center;
	border-radius: 5px;
	background: #fff;
	padding: 4px;
	margin-left: auto;
	margin-right: 10px;

	> svg {
		color: rgb(255, 70, 70);
	}

	> span {
		margin-left: 5px;
	}
`

const IssueMoreButton = styled.div`
	flex-grow: 0;
	margin-left: auto;
	margin-right: 5px;
	cursor: pointer;
	opacity: 0;
`

const Issue = styled.div`
	padding: 5px 8px;
	margin-bottom: 1px;
	color: #333;
	background: rgba(230, 230, 230, 0.8);
	border-left: 2px solid rgb(255, 70, 70);

	&:hover {
		${IssueMoreButton} {
			opacity: 0.6;
		}
	}
`

const IssueMain = styled.div`
	display: flex;
	align-content: center;
	overflow: hidden;

	> svg {
		color: rgb(255, 70, 70);
	}
`

const IssueMore = styled.div`
	padding-top: 5px;
`

const IssueMessage = styled.div`
	margin-left: 5px;
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

const IssueSource = styled.div`
	color: #666;
	margin-left: 5px;
	cursor: pointer;
`

const IssuesContainer = styled.div`
	overflow: auto;
`
