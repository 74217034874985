import { TranslationHelper } from '@/context/Locale'
import { GraphNodeType } from '../types'
import { MddWidgetModel } from '../utils'

export const nodeTypeToTitle = (node: MddWidgetModel, t: TranslationHelper) => {
	switch (node.node.type) {
		case GraphNodeType.INIT:
			return t('INIT_NODE')
		case GraphNodeType.FINISH:
			return t('FINISH_NODE')
		case GraphNodeType.PARALLEL_START:
			return t('PARALLEL_START')
		case GraphNodeType.PARALLEL_MERGE:
			return t('PARALLEL_MERGE')
		case GraphNodeType.PARALLEL_MERGE:
			return t('PARALLEL_MERGE')
		case GraphNodeType.EXECUTION_WRITE:
			return t('EXECUTE_WRITE_NODE')
		case GraphNodeType.EXECUTION_READ:
			return t('EXECUTE_READ_NODE')
		case GraphNodeType.WAIT_FOR_EVENT:
			return t('WAIT_FOR_EVENT')
		case GraphNodeType.LOCK_RESOURCE:
			return t('LOCK_RESOURCE')
		case GraphNodeType.UNLOCK_RESOURCE:
			return t('UNLOCK_RESOURCE')
		case GraphNodeType.SUBFLOW_EXECUTE:
			return t('SUBFLOW_EXECUTE')
		default:
			return t('UNKNOWN')
	}
}
