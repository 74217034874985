import React from 'react'
import { MddWidgetModel } from '../../../utils'
import colors from '@/styles/colors'

export const FinishWidget = ({
	size,
	node,
	ports
}: {
	size: number
	node: MddWidgetModel
	ports: React.ReactNode
}) => {
	return (
		<div
			className={'mdd-node'}
			style={{
				position: 'relative',
				width: size,
				height: size
			}}
		>
			<svg width={size} height={size}>
				<circle
					cx={size / 2}
					cy={size / 2}
					r={size / 3}
					fill="white"
					stroke={node.isSelected() ? colors.graph.selectedBorder : 'black'}
					strokeWidth="1"
				/>
			</svg>
			{ports}
		</div>
	)
}
