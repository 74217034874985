import { loadSystemMappings } from '@/store/modules/system/actions'
import { NativeMap } from '@/utils/collections'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import { BaseEntityEvent } from '@projectstorm/react-canvas-core'
import {
	DefaultLinkModel,
	DiagramModel,
	DiagramModelGenerics,
	PortModelAlignment
} from '@projectstorm/react-diagrams'
import { debounce } from 'debounce'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Controls } from './components/Controls/Controls'
import { Layer } from './components/Layer'
import { RightPanel } from './components/RightPanel/RightPanel'
import { GraphContext } from './context/GraphContext'
import { decodeGraphData, encodeGraphData } from './data'
import { getEngine } from './engine'
import { GraphEditorData, GraphNodeType, NodeCategory } from './types'
import { MddWidgetModel } from './utils'
import { createIssue, GraphError, validateGraph } from './validator'

type Props = {
	systemNodeId: number
	nodeId: number
	data?: GraphEditorData
	offset: { x: number; y: number }
	zoom: number
	editMode: boolean
	nodes: NodeCategory[]
	hideBasicNodes?: boolean
	onMove: (offset: { x: number; y: number }) => void
	onZoom: (zoom: number) => void
	onChange: (update: GraphEditorData) => void
}

export const GraphEditor = ({
	systemNodeId,
	nodeId,
	data,
	zoom,
	offset,
	editMode,
	onChange,
	onMove,
	onZoom,
	nodes,
	hideBasicNodes
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const [issues, setIssues] = useState([] as GraphError[])

	const [selected, setSelected] = useState(
		undefined as undefined | MddWidgetModel
	)

	useEffect(() => {
		dispatch(loadSystemMappings(systemNodeId))
		handleChange()
	}, [])

	const engine = useMemo(() => {
		return getEngine()
	}, [])

	const handleSelection = useCallback(
		(
			e: BaseEntityEvent<MddWidgetModel> & {
				isSelected: boolean
			}
		) => {
			if (e.isSelected) {
				setSelected(e.entity)
			} else {
				setSelected(undefined)
			}
		},
		[]
	)

	const issuesUpdate = useCallback(
		(model: DiagramModel<DiagramModelGenerics>) => {
			const nodes = model.getNodes() as MddWidgetModel[]

			const issues = validateGraph(model, t)

			const hasInit = !!nodes.find(
				node =>
					node instanceof MddWidgetModel &&
					node.node.type === GraphNodeType.INIT
			)

			const hasFinish = !!nodes.find(
				node =>
					node instanceof MddWidgetModel &&
					node.node.type === GraphNodeType.FINISH
			)

			if (!hasInit) {
				issues.push(createIssue(t('GRAPH_EDITOR_ISSUE_NO_INIT_NODE')))
			}

			if (!hasFinish) {
				issues.push(createIssue(t('GRAPH_EDITOR_ISSUE_NO_FINISH_NODE')))
			}

			setIssues(issues)
		},
		[t]
	)

	const handleChange = useCallback(
		debounce(async () => {
			// Serialize nodes and links
			issuesUpdate(model)
			const encodedData = encodeGraphData(model)
			onChange(encodedData)

			setSelected(prev =>
				model
					.getNodes()
					.some(node => (node as MddWidgetModel).node.id === prev?.node.id)
					? prev
					: undefined
			)
		}),
		[editMode, onChange, issuesUpdate, engine]
	)

	const graphData = useMemo(() => (data ? decodeGraphData(data) : undefined), [
		data
	])

	const model = useMemo(() => {
		const model = new DiagramModel()

		// Load previous state if present
		if (graphData && graphData.nodes.length > 0) {
			const createdNodes = {} as NativeMap<MddWidgetModel>

			graphData.nodes.forEach(saved => {
				const node = new MddWidgetModel(
					systemNodeId,
					nodeId,
					saved,
					handleChange
				)

				node.setPosition(saved.x, saved.y)
				node.setLocked(!editMode)

				if (selected?.node.id === saved.id) {
					node.setSelected(true)
				}

				node.registerListener({
					positionChanged: handleChange,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					selectionChanged: handleSelection as any
				})

				model.addNode(node)
				createdNodes[saved.id] = node
			})

			graphData.links.forEach(saved => {
				const sourceNode = createdNodes[saved.from]
				const targetNode = createdNodes[saved.to]

				if (!sourceNode || !targetNode) {
					console.warn(
						t('GRAPH_EDITOR_UNABLE_FIND_TARGET'),
						saved,
						'src',
						sourceNode,
						'target',
						targetNode
					)

					return
				}

				const sourcePort = sourceNode.getPort(PortModelAlignment.RIGHT)
				const targetPort = targetNode.getPort(PortModelAlignment.LEFT)

				if (!sourcePort || !targetPort) {
					console.warn(
						t('GRAPH_EDITOR_UNABLE_FIND_TARGET'),
						saved,
						'src',
						sourcePort,
						'target',
						targetPort
					)

					return
				}

				const link = new DefaultLinkModel()
				link.setSourcePort(sourcePort)
				link.setTargetPort(targetPort)
				link.setLocked(!editMode)

				link.registerListener({
					entityRemoved: handleChange,
					sourcePortChanged: handleChange,
					targetPortChanged: handleChange
				})

				model.addLink(link)
			})
		} else {
			// Initial state with init and finish
			const init = new MddWidgetModel(
				systemNodeId,
				nodeId,
				{ id: '', type: GraphNodeType.INIT, x: 0, y: 0 },
				handleChange
			)

			init.setPosition(100, 100)
			init.setLocked(!editMode)

			init.registerListener({
				positionChanged: handleChange,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				selectionChanged: handleSelection as any
			})

			model.addAll(init)
		}

		model.setZoomLevel(zoom * 100)
		model.setOffset(offset.x, offset.y)

		engine.setModel(model)

		model.registerListener({
			nodesUpdated: handleChange
		})

		issuesUpdate(model)

		return model
	}, [engine, handleChange, editMode, issuesUpdate])

	const handleNodeFocus = useCallback(
		(node: MddWidgetModel) => {
			model.getSelectedEntities().forEach(e => e.setSelected(false))
			node.setSelected(true)

			const zoom = model.getZoomLevel() / 100
			const bb = node.getBoundingBox()

			const xPos =
				(node.getX() + bb.getWidth() * 0.5) * zoom -
				engine.getCanvas().clientWidth * 0.5

			const yPos =
				(node.getY() + bb.getHeight() * 0.5) * zoom -
				engine.getCanvas().clientHeight * 0.3

			model.setOffset(-xPos, -yPos)
			engine.repaintCanvas()
		},
		[engine, model]
	)

	return (
		<GraphContext nodeId={nodeId}>
			<GraphContainer>
				<Controls
					engine={engine}
					usedNodes={graphData?.nodes || []}
					editMode={editMode}
					enableBasicNodes={!hideBasicNodes}
					availableNodes={nodes}
				/>
				<Layer
					key={model.getID()}
					nodeId={nodeId}
					model={model}
					engine={engine}
					onChange={handleChange}
					systemNodeId={systemNodeId}
					offset={offset}
					zoom={zoom}
					onMove={onMove}
					onZoom={onZoom}
					onSelection={handleSelection}
				/>
				<RightPanel
					nodeId={nodeId}
					systemNodeId={systemNodeId}
					issues={issues}
					onNodeFocus={handleNodeFocus}
					selected={selected}
					editMode={editMode}
					engine={engine}
					model={model}
				/>
			</GraphContainer>
		</GraphContext>
	)
}

const GraphContainer = styled.div`
	position: relative;
	height: 100%;
	display: flex;
`
