import { Button } from '@/components'
import { useAppContext } from '@/utils/hooks'
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { DiagramEngine } from '@projectstorm/react-diagrams'
import React from 'react'
import styled from 'styled-components'
import { engineFitNodes } from '../../utils'
import { Items } from './components/Items'
import { GraphNode, NodeCategory } from '../../types'

type Props = {
	engine: DiagramEngine
	editMode: boolean
	usedNodes: GraphNode[]
	availableNodes: NodeCategory[]
	enableBasicNodes: boolean
}

const ControlsComponent = ({
	engine,
	editMode,
	usedNodes = [],
	enableBasicNodes,
	availableNodes
}: Props) => {
	const { t } = useAppContext()

	const handleFitClick = () => {
		engineFitNodes(engine)
	}

	return (
		<Container>
			<ControlButton
				size="sm"
				icon={faExpandArrowsAlt}
				tooltip={t('ZOOM_TO_FIT')}
				onClick={handleFitClick}
			/>
			<Separator />
			{editMode && (
				<Items
					usedNodes={usedNodes}
					enableBasicNodes={enableBasicNodes}
					availableNodes={availableNodes}
				/>
			)}
		</Container>
	)
}

const ControlButton = styled(Button)`
	margin: 5px 0;
`

const Separator = styled.div`
	width: 100%;
	height: 1px;
	background: #ddd;
`

const Container = styled.div`
	border-right: 1px solid #eee;
	flex-grow: 0;
	padding: 0.5rem;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
`

export const Controls = ControlsComponent
