import { getWorkflowsOfSystemNode } from '@/api'
import { useApi } from '@/api/hooks'
import { GraphNode } from '@/components/GraphEditor/types'
import { TextFormField, SelectFormField } from '@/components/UberForm'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { useAppContext } from '@/utils/hooks'
import React from 'react'

type Props = {
	node: GraphNode
	systemId: number
}

export const SubflowExecuteProps = ({ systemId }: Props) => {
	const { t } = useAppContext()
	const { data: workflows } = useApi(getWorkflowsOfSystemNode(systemId))

	return (
		<>
			<SelectFormField
				name="subworkflowCode"
				title={t('SUBWORKFLOW')}
				options={workflows}
				valueKey="code"
				labelKey="name"
				allowEmpty
				clearable
			/>
			<TextFormField title={t('INPUT_PARAMETER')} name="inputParameter" />
			<CheckboxFormField
				title={t('WAIT_UNTIL_FINISHED')}
				name="waitUntilFinishedFlag"
			/>
			<CheckboxFormField title={t('ON_OFF')} name="onOffFlag" />
		</>
	)
}
