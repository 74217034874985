import { isNotUndefined } from '@/utils/collections'
import { DiagramModel, PortModelAlignment } from '@projectstorm/react-diagrams'
import {
	InitGraphNode,
	GraphNodeType,
	FinishGraphNode,
	ExecuteWriteGraphNode,
	ExecuteReadGraphNode,
	ParallelMergeGraphNode,
	ParallelStartGraphNode,
	GraphEditorData,
	WaitForEventGraphNode,
	LockResourceGraphNode,
	UnlockResourceGraphNode,
	SubflowExecuteGraphNode
} from './types'
import { MddWidgetModel, nodesByType } from './utils'

export const encodeGraphData = (model: DiagramModel) => {
	const nodes = model.getNodes() as MddWidgetModel[]
	const links = model.getLinks()

	return {
		initNode: nodesByType<InitGraphNode>(nodes, GraphNodeType.INIT)[0],
		finishNode: nodesByType<FinishGraphNode>(nodes, GraphNodeType.FINISH)[0],
		executeWriteNodes: nodesByType<ExecuteWriteGraphNode>(
			nodes,
			GraphNodeType.EXECUTION_WRITE
		),
		executeReadNodes: nodesByType<ExecuteReadGraphNode>(
			nodes,
			GraphNodeType.EXECUTION_READ
		),
		parallelMergeNodes: nodesByType<ParallelMergeGraphNode>(
			nodes,
			GraphNodeType.PARALLEL_MERGE
		),
		parallelStartNodes: nodesByType<ParallelStartGraphNode>(
			nodes,
			GraphNodeType.PARALLEL_START
		),
		waitForEventNodes: nodesByType<WaitForEventGraphNode>(
			nodes,
			GraphNodeType.WAIT_FOR_EVENT
		),
		lockResourceNodes: nodesByType<LockResourceGraphNode>(
			nodes,
			GraphNodeType.LOCK_RESOURCE
		),
		unlockResourceNodes: nodesByType<UnlockResourceGraphNode>(
			nodes,
			GraphNodeType.UNLOCK_RESOURCE
		),
		subflowExecuteNodes: nodesByType<SubflowExecuteGraphNode>(
			nodes,
			GraphNodeType.SUBFLOW_EXECUTE
		),
		links: links
			.filter(link => link.getSourcePort() && link.getTargetPort())
			.map(link => {
				return link.getSourcePort().getOptions().alignment ===
					PortModelAlignment.RIGHT
					? { src: link.getSourcePort(), dst: link.getTargetPort() }
					: {
							src: link.getTargetPort(),
							dst: link.getSourcePort()
					  }
			})
			.map(({ src, dst }) => ({
				from: (src.getNode() as MddWidgetModel).node.id,
				to: (dst.getNode() as MddWidgetModel).node.id
			}))
	} as GraphEditorData
}

const setType = <T extends { type: GraphNodeType }>(
	v: T[],
	type: GraphNodeType
): T[] => v.map(i => ({ ...i, type }))

export const decodeGraphData = (data: GraphEditorData) => ({
	nodes: [
		data.initNode,
		data.finishNode,
		...setType(data.executeReadNodes ?? [], GraphNodeType.EXECUTION_READ),
		...setType(data.executeWriteNodes ?? [], GraphNodeType.EXECUTION_WRITE),
		...setType(data.parallelMergeNodes ?? [], GraphNodeType.PARALLEL_MERGE),
		...setType(data.parallelStartNodes ?? [], GraphNodeType.PARALLEL_START),
		...setType(data.waitForEventNodes ?? [], GraphNodeType.WAIT_FOR_EVENT),
		...setType(data.lockResourceNodes ?? [], GraphNodeType.LOCK_RESOURCE),
		...setType(data.unlockResourceNodes ?? [], GraphNodeType.UNLOCK_RESOURCE),
		...setType(data.subflowExecuteNodes ?? [], GraphNodeType.SUBFLOW_EXECUTE)
	].filter(isNotUndefined),
	links: data.links ?? []
})
