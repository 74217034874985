import React from 'react'
import { MddWidgetModel } from '@/components/GraphEditor/utils'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useAppContext } from '@/utils/hooks'
import { nodeTypeToTitle } from '@/components/GraphEditor/utils/nodeTypeToTitle'
import { useNodeData } from '@/components/GraphEditor/hooks/useNodeData'

type Props = {
	node: MddWidgetModel
}

export const Title = ({ node }: Props) => {
	const { t } = useAppContext()

	const { type } = node.node
	const nodeData = useNodeData(node.node)
	const name = nodeData?.name

	const title = useMemo(() => {
		if (name) {
			return name
		}

		return nodeTypeToTitle(node, t)
	}, [name, type, t])

	return <Wrapper>{title}</Wrapper>
}

const Wrapper = styled.div`
	padding: 10px;
	text-align: center;
`
