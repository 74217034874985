import React, { createContext, useContext, useMemo } from 'react'

const Context = createContext({
	nodeId: -1
})

export const useGraphContext = () => useContext(Context)

type Props = {
	nodeId: number
	children: React.ReactNode
}

export const GraphContext = ({ nodeId, children }: Props) => {
	const value = useMemo(
		() => ({
			nodeId
		}),
		[nodeId]
	)

	return <Context.Provider value={value}>{children}</Context.Provider>
}
