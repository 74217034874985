import { StructureDto } from '@/api/models'
import { GraphEditor } from '@/components/GraphEditor/GraphEditor'
import { GraphEditorData, GraphNodeType } from '@/components/GraphEditor/types'
import { updateWorkflowGraphData } from '@/store/modules/workflow/actions'
import {
	OpenedWorkflowData,
	WorkflowDataForm
} from '@/store/modules/workflow/types'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import React, { useCallback, useMemo } from 'react'

export const Graph = ({
	systemNodeId,
	data,
	node,
	onChange
}: {
	systemNodeId: number
	data: OpenedWorkflowData
	node: StructureDto
	onChange: (update: UpdateDeepPartial<WorkflowDataForm>) => void
}) => {
	const { t } = useAppContext()

	const dispatch = useAppDispatch()

	const offset = data.graph?.offset ?? { x: 0, y: 0 }
	const zoom = data.graph?.zoom ?? 1

	const availableNodes = useMemo(
		() => [
			{
				name: t('FUNCTIONAL_NODES'),
				items: [
					{
						type: GraphNodeType.EXECUTION_WRITE,
						name: t('EXECUTE_WRITE_NODE')
					},
					{
						type: GraphNodeType.WAIT_FOR_EVENT,
						name: t('WAIT_FOR_EVENT')
					},
					{
						type: GraphNodeType.LOCK_RESOURCE,
						name: t('LOCK_RESOURCE')
					},
					{
						type: GraphNodeType.UNLOCK_RESOURCE,
						name: t('UNLOCK_RESOURCE')
					},
					{
						type: GraphNodeType.SUBFLOW_EXECUTE,
						name: t('SUBFLOW_EXECUTE')
					}
				]
			}
		],
		[t]
	)

	const handleMove = (offset: { x: number; y: number }) => {
		dispatch(
			updateWorkflowGraphData(node, {
				offset
			})
		)
	}

	const handleZoom = (zoom: number) => {
		dispatch(
			updateWorkflowGraphData(node, {
				zoom
			})
		)
	}

	const handleChange = useCallback(
		(update: GraphEditorData) => {
			onChange({
				graph: update
			})
		},
		[onChange]
	)

	return (
		<GraphEditor
			data={data.form.graph}
			nodeId={node.id}
			offset={offset}
			zoom={zoom}
			editMode={data.parsedEditMode}
			onMove={handleMove}
			onZoom={handleZoom}
			onChange={handleChange}
			systemNodeId={systemNodeId}
			nodes={availableNodes}
		/>
	)
}

export default Graph
