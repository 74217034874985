export type NodeCategory = {
	name: string
	items: NodeItem[]
}

export type NodeItem = {
	type: GraphNodeType
	name: string
	mapping?: number
}

export type GraphEditorData = {
	initNode?: InitGraphNode
	finishNode?: FinishGraphNode
	executeWriteNodes?: ExecuteWriteGraphNode[]
	executeReadNodes?: ExecuteReadGraphNode[]
	parallelMergeNodes?: ParallelMergeGraphNode[]
	parallelStartNodes?: ParallelStartGraphNode[]
	waitForEventNodes?: WaitForEventGraphNode[]
	lockResourceNodes?: LockResourceGraphNode[]
	unlockResourceNodes?: UnlockResourceGraphNode[]
	subflowExecuteNodes?: SubflowExecuteGraphNode[]
	links?: GraphLink[]
}

type BaseGraphNode = {
	id: string
	x: number
	y: number
	name?: string
	throwEvent?: string
}

export type GraphNode =
	| InitGraphNode
	| FinishGraphNode
	| ParallelMergeGraphNode
	| ParallelStartGraphNode
	| ExecuteWriteGraphNode
	| ExecuteReadGraphNode
	| WaitForEventGraphNode
	| SubflowExecuteGraphNode
	| LockResourceGraphNode
	| UnlockResourceGraphNode

export interface GraphLink {
	from: string
	to: string
}

export enum GraphNodeType {
	INIT = 'init',
	FINISH = 'finish',
	PARALLEL_START = 'parallel_start',
	PARALLEL_MERGE = 'parallel_merge',
	EXECUTION_WRITE = 'execution_write',
	EXECUTION_READ = 'execution_read',
	WAIT_FOR_EVENT = 'wait_for_event',
	LOCK_RESOURCE = 'lock_resource',
	UNLOCK_RESOURCE = 'unlock_resource',
	SUBFLOW_EXECUTE = 'subflow_execute'
}

export type InitGraphNode = BaseGraphNode & {
	type: GraphNodeType.INIT
	start?: StartType
	interval?: StartInterval
	hour?: number
	minute?: number
	day?: number
	weekday?: number
	cron?: string
	script?: string
	otherWorkflowCode?: string
}

export type FinishGraphNode = BaseGraphNode & {
	type: GraphNodeType.FINISH
	script?: string
}

export type ExecuteWriteGraphNode = BaseGraphNode & {
	type: GraphNodeType.EXECUTION_WRITE
	method?: ExecutionMethod
	mappingCode?: string
	script?: string
	onOffFlag?: boolean
	inputParameter?: string
	waitUntilFinishedFlag?: boolean
}

export type ExecuteReadGraphNode = BaseGraphNode & {
	type: GraphNodeType.EXECUTION_READ
	mappingCode?: string
	onOffFlag?: boolean
	inputParameter?: string
	waitUntilFinishedFlag?: boolean
}

export type ParallelMergeGraphNode = BaseGraphNode & {
	type: GraphNodeType.PARALLEL_MERGE
	onOffFlag?: boolean
}

export type ParallelStartGraphNode = BaseGraphNode & {
	type: GraphNodeType.PARALLEL_START
	onOffFlag?: boolean
}

export type WaitForEventGraphNode = BaseGraphNode & {
	type: GraphNodeType.WAIT_FOR_EVENT
	eventName?: string
	onOffFlag?: boolean
}

export type LockResourceGraphNode = BaseGraphNode & {
	type: GraphNodeType.LOCK_RESOURCE
	inputParameter?: string
	resourceName?: string
	onOffFlag?: boolean
}

export type UnlockResourceGraphNode = BaseGraphNode & {
	type: GraphNodeType.UNLOCK_RESOURCE
	inputParameter?: string
	resourceName?: string
	onOffFlag?: boolean
}

export type SubflowExecuteGraphNode = BaseGraphNode & {
	type: GraphNodeType.SUBFLOW_EXECUTE
	inputParameter?: string
	waitUntilFinishedFlag?: boolean
	onOffFlag?: boolean
	subworkflowCode?: string
}

export enum ExecutionMethod {
	CallMapping = 'call_mapping',
	RunMapping = 'run_mapping',
	RunScript = 'run_script'
}

export enum StartInterval {
	Daily = 'daily',
	Weekly = 'weekly',
	Monthly = 'monthly'
}

export enum StartType {
	Interval = 'interval',
	Cron = 'cron',
	WorkflowFinish = 'workflow_finish',
	RunScript = 'run_script'
}
