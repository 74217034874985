import React from 'react'
import { GraphNodeType } from '@/components/GraphEditor/types'
import { MddWidgetModel } from '@/components/GraphEditor/utils'
import {
	faBookReader,
	faCompress,
	faCompressArrowsAlt,
	faExpand,
	faExpandArrowsAlt,
	faHourglassStart,
	faLock,
	faLockOpen,
	faPenAlt,
	faPlay,
	faSignOutAlt,
	faStream,
	IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'

type Props = {
	nodeType: GraphNodeType
}

export const TYPE_TO_ICON: Record<GraphNodeType, IconDefinition> = {
	[GraphNodeType.INIT]: faPlay,
	[GraphNodeType.FINISH]: faSignOutAlt,
	[GraphNodeType.PARALLEL_START]: faExpandArrowsAlt,
	[GraphNodeType.PARALLEL_MERGE]: faCompressArrowsAlt,
	[GraphNodeType.EXECUTION_WRITE]: faPenAlt,
	[GraphNodeType.EXECUTION_READ]: faBookReader,
	[GraphNodeType.WAIT_FOR_EVENT]: faHourglassStart,
	[GraphNodeType.LOCK_RESOURCE]: faLock,
	[GraphNodeType.UNLOCK_RESOURCE]: faLockOpen,
	[GraphNodeType.SUBFLOW_EXECUTE]: faStream
}

export const NodeIcon = ({ nodeType }: Props) => {
	const icon = useMemo(() => TYPE_TO_ICON[nodeType], [nodeType])

	return <FontAwesomeIcon icon={icon} style={{ marginLeft: '10px' }} />
}
