import React from 'react'
import { Form, TextFormField } from '@/components/UberForm'
import { useAppContext } from '@/utils/hooks'
import { GraphNode } from '@/components/GraphEditor/types'
import { FormlessFormField } from '@/components/UberForm/FormField'

type Props = {
	node?: GraphNode
	onChange: (v: GraphNode, source: FormlessFormField<any> | undefined) => void
	editMode: boolean
	formRef: React.RefObject<Form<any>>
	children?: React.ReactNode
	afterName?: React.ReactNode
}

export const FormShared = ({
	node,
	onChange,
	editMode,
	formRef,
	children,
	afterName
}: Props) => {
	const { t } = useAppContext()

	return (
		<>
			<Form
				initialValues={node}
				onChange={onChange}
				disabled={!editMode}
				ref={formRef}
			>
				<TextFormField title={t('NAME')} name="name" />
				{afterName}
				<TextFormField title={t('THROW_EVENT')} name="throwEvent" />
				{children}
			</Form>
		</>
	)
}
