import { StructureDto } from '@/api/models'
import {
	GraphNode,
	GraphNodeType,
	StartInterval,
	StartType
} from '@/components/GraphEditor/types'
import { TimeField } from '@/components/TimeField/TimeField'
import {
	SelectFormField,
	TextAreaFormField,
	TextFormField,
	Validators
} from '@/components/UberForm'
import NumberFormField from '@/components/UberForm/Helpers/NumberFormField'
import { loadNodeChildren } from '@/store/modules/node/actions'
import { isNotUndefined } from '@/utils/collections'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import React, { useEffect, useMemo, useState } from 'react'

type Props = {
	nodeId: number
	node: GraphNode
}

export const InitProps = ({ node, nodeId }: Props) => {
	const { t } = useAppContext()

	const initNode = useMemo(
		() => (node.type === GraphNodeType.INIT ? node : undefined),
		[node.type]
	)

	const [interval, setInterval] = useState(
		initNode?.interval as StartInterval | undefined
	)

	const [startType, setStartType] = useState(
		initNode?.start as StartType | undefined
	)

	const dispatch = useAppDispatch()

	const nodes = useAppStore(state => state.node.nodes)

	const workflows = useAppStore(
		state =>
			state.node.children[nodes[nodeId]?.parentStructureId as number]
				?.map(id => nodes[id])
				.filter(isNotUndefined)
				.filter(
					n =>
						n.id !== nodeId &&
						[
							StructureDto.TypeEnum.WORKFLOW,
							StructureDto.TypeEnum.API_NODE
						].includes(n.type)
				) || []
	)

	const otherWorkflows = useMemo(() => workflows.filter(m => m.id !== nodeId), [
		workflows
	])

	const types = useMemo(
		() => [
			{ label: t('INIT_TYPE_INTERVAL'), value: StartType.Interval },
			{ label: t('INIT_TYPE_CRON'), value: StartType.Cron },
			{
				label: t('INIT_TYPE_WORKFLOW_FINISH'),
				value: StartType.WorkflowFinish
			},
			{ label: t('INIT_TYPE_SCRIPT'), value: StartType.RunScript }
		],
		[]
	)

	const intervals = useMemo(
		() => [
			{ label: t('INIT_INTERVAL_DAILY'), value: StartInterval.Daily },
			{ label: t('INIT_INTERVAL_WEEKLY'), value: StartInterval.Weekly },
			{ label: t('INIT_INTERVAL_MONTHLY'), value: StartInterval.Monthly }
		],
		[]
	)

	const weekdays = useMemo(
		() => [
			{ label: t('MONDAY'), value: 'monday' },
			{ label: t('TUESDAY'), value: 'tuesday' },
			{ label: t('WEDNESDAY'), value: 'wednesday' },
			{ label: t('THURSDAY'), value: 'thursday' },
			{ label: t('FRIDAY'), value: 'friday' },
			{ label: t('SATURDAY'), value: 'saturday' },
			{ label: t('SUNDAY'), value: 'sunday' }
		],
		[]
	)

	useEffect(() => {
		dispatch(loadNodeChildren(nodes[nodeId]?.parentStructureId as number))
	}, [nodeId])

	const validators = useMemo(
		() => ({
			day: [Validators.numeric(true, 1, 31)]
		}),
		[]
	)

	return (
		<>
			<SelectFormField
				title={t('INIT_TYPE')}
				name="start"
				onChange={v => setStartType(v as StartType)}
				options={types}
			/>

			{startType === StartType.Interval && (
				<>
					<SelectFormField
						title={t('INIT_INTERVAL')}
						name="interval"
						onChange={v => setInterval(v as StartInterval)}
						options={intervals}
					/>

					{interval === StartInterval.Weekly && (
						<SelectFormField
							title={t('INIT_WEEKDAY')}
							name="weekday"
							options={weekdays}
						/>
					)}

					{interval === StartInterval.Monthly && (
						<NumberFormField
							title={t('INIT_DAY')}
							name="day"
							validators={validators.day}
						/>
					)}

					{interval && (
						<TimeField
							hourName="hour"
							minuteName="minute"
							title={t('INIT_TIME')}
						/>
					)}
				</>
			)}

			{startType === StartType.WorkflowFinish && (
				<SelectFormField
					title={t('INIT_OTHER_WORKFLOW')}
					name="otherWorkflowCode"
					options={otherWorkflows}
					valueKey="code"
					labelKey="name"
				/>
			)}

			{startType === StartType.Cron && (
				<TextFormField title={t('INIT_CRON')} name="cron" />
			)}

			{startType === StartType.RunScript && (
				<TextAreaFormField title={t('INIT_SCRIPT')} name="script" rows={20} />
			)}
		</>
	)
}
