import { DiagramModel } from '@projectstorm/react-diagrams'
import { MddWidgetModel } from './utils'
import { Locale, TranslationHelper } from '@/context/Locale'

export interface GraphError {
	id: string
	node?: MddWidgetModel
	message: string
}

export const createIssue = (
	message: string,
	node?: MddWidgetModel
): GraphError => ({
	id: node ? `${node.node.id}-${message}` : message,
	message,
	node
})

const getPortName = (t: TranslationHelper, p: string) => {
	switch (p.toLowerCase()) {
		case 'left':
			return t('LEFT_PORT')
		case 'right':
			return t('RIGHT_PORT')
		default:
			return p
	}
}

export const validateGraph = (model: DiagramModel, t: TranslationHelper) => {
	const errors = [] as GraphError[]
	const nodes = model.getNodes()

	Object.values(nodes).forEach(node => {
		if (node instanceof MddWidgetModel) {
			errors.push(
				...node.validate(t).map(message => createIssue(message, node))
			)

			Object.values(node.getPorts()).forEach(port => {
				if (Object.values(port.getLinks()).length === 0) {
					errors.push(
						createIssue(
							t('UNUSED_PORT', [getPortName(t, port.getName())]),
							node
						)
					)
				}
			})
		}
	})

	return errors
}
