import { GraphNode } from '@/components/GraphEditor/types'
import { TextFormField } from '@/components/UberForm'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { useAppContext } from '@/utils/hooks'
import React from 'react'

type Props = {
	node: GraphNode
}

export const LockResourceProps = ({}: Props) => {
	const { t } = useAppContext()

	return (
		<>
			<TextFormField title={t('INPUT_PARAMETER')} name="inputParameter" />
			<TextFormField title={t('RESOURCE_NAME')} name="resourceName" />
			<CheckboxFormField title={t('ON_OFF')} name="onOffFlag" />
		</>
	)
}
