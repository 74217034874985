import { getNodesOfSpecificParent } from '@/api'
import { useApi } from '@/api/hooks'
import { GraphNodeType } from '@/components/GraphEditor/types'
import { useAppContext } from '@/utils/hooks'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { MddWidgetModel } from '../../../utils'

type Props = {
	node: MddWidgetModel
	ports: React.ReactNode
}

const ExecutionReadWidgetComponent = ({ node, ports }: Props) => {
	const { t } = useAppContext()

	const nodeChildren = useApi(getNodesOfSpecificParent(node.nodeId as number))

	const selectedMapping = useMemo(() => {
		const mappingCode =
			node.node.type === GraphNodeType.EXECUTION_READ
				? node.node.mappingCode
				: undefined

		return nodeChildren.data?.find(n => n.code === mappingCode)
	}, [node, nodeChildren])

	return (
		<Execution selected={node.isSelected()}>
			<Title>
				{selectedMapping
					? t('EXECUTE_READ_RUN_MAPPING', [
							selectedMapping.name ?? t('EXECUTE_MAPPING_UNSET')
					  ])
					: t('EXECUTE_READ_NODE')}
			</Title>
			{ports}
		</Execution>
	)
}

const Title = styled.div`
	padding: 10px;
	border-bottom: 1px solid #eee;
	text-align: center;
`

const Execution = styled.div<{ selected: boolean }>`
	border: 1px solid #999;
	box-sizing: border-box;
	min-width: 200px;
	background: #fff;

	${props => css`
		&:hover {
			border-color: transparent;
			box-shadow: 0 0 0 2px ${props.theme.colors.graph.selectedBorder};
		}
	`}

	${props =>
		props.selected &&
		css`
			border-color: transparent;
			box-shadow: 0 0 0 2px ${props.theme.colors.graph.selectedBorder};
		`}
`

export const ExecutionReadWidget = ExecutionReadWidgetComponent
