import { GraphNode } from '@/components/GraphEditor/types'
import { TextFormField } from '@/components/UberForm'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { useAppContext } from '@/utils/hooks'
import React from 'react'

type Props = {
	node: GraphNode
}

export const WaitForEventProps = ({}: Props) => {
	const { t } = useAppContext()

	return (
		<>
			<TextFormField title={t('EVENT_NAME')} name="eventName" />
			<CheckboxFormField title={t('ON_OFF')} name="onOffFlag" />
		</>
	)
}
