import createEngine, { DefaultDiagramState } from '@projectstorm/react-diagrams'
import { engineFitNodes, MddNodeFactory } from './utils'

export const getEngine = () => {
	const engine = createEngine()

	// Don't allow loose links
	const state = engine.getStateMachine().getCurrentState()

	if (state instanceof DefaultDiagramState) {
		state.dragNewLink.config.allowLooseLinks = false
	}

	// Allow our custom nodes
	engine.getNodeFactories().registerFactory(new MddNodeFactory())

	// Disable line splitting
	engine.setMaxNumberPointsPerLink(0)

	// Fit when ready
	engine.registerListener({
		canvasReady: () => {
			if (
				engine.getModel().getOffsetX() === 0 &&
				engine.getModel().getOffsetY() === 0
			) {
				if (Object.values(engine.getModel().getNodes()).length > 1) {
					engineFitNodes(engine)
				}
			}
		}
	})

	return engine
}
