import { StructureDto } from '@/api/models'
import { Locale, TranslationHelper } from '@/context/Locale'
import { NativeMap } from '@/utils/collections'
import {
	AbstractReactFactory,
	GenerateWidgetEvent
} from '@projectstorm/react-canvas-core'
import {
	DefaultLinkModel,
	DiagramEngine,
	NodeModel,
	NodeModelGenerics,
	PortModel,
	PortModelAlignment
} from '@projectstorm/react-diagrams'
import React, { createContext } from 'react'
import { MddWidget } from './components/MddWidget/MddWidget'
import {
	ExecutionMethod,
	GraphEditorData,
	GraphNode,
	GraphNodeType,
	StartInterval,
	StartType
} from './types'

export class DefaultPort extends PortModel {
	onChange: () => void

	constructor(
		alignment: PortModelAlignment,
		type: 'in' | 'out' | 'in-parallel' | 'out-parallel',
		onChange: () => void
	) {
		super({
			name: alignment,
			alignment,
			type
		})

		this.onChange = onChange
	}

	createLinkModel() {
		if (
			this.options.type !== 'out-parallel' &&
			this.options.type !== 'in-parallel'
		) {
			Object.values(this.getLinks()).forEach(link => link.remove())
		}

		const model = new DefaultLinkModel()

		model.registerListener({
			sourcePortChanged: this.onChange,
			targetPortChanged: this.onChange,
			entityRemoved: this.onChange
		})

		return model
	}

	canLinkToPort(port: PortModel): boolean {
		if (port instanceof DefaultPort) {
			switch (port.options.type) {
				case 'in': {
					return Object.keys(port.getLinks()).length === 0
				}

				case 'out-parallel':

				case 'out': {
					return false
				}

				case 'in-parallel': {
					return true
				}
			}
		}

		return true
	}
}

export class MddWidgetModel extends NodeModel<NodeModelGenerics> {
	systemId: number
	nodeId: number

	node: GraphNode

	onChange: () => void

	constructor(
		systemId: number,
		nodeId: number,
		node: GraphNode,
		onChange: () => void
	) {
		super({
			type: 'mdd-widget'
		})

		this.nodeId = nodeId
		this.systemId = systemId
		this.onChange = onChange

		this.node = node

		if (
			[
				GraphNodeType.INIT,
				GraphNodeType.EXECUTION_READ,
				GraphNodeType.EXECUTION_WRITE,
				GraphNodeType.PARALLEL_START,
				GraphNodeType.PARALLEL_MERGE,
				GraphNodeType.WAIT_FOR_EVENT,
				GraphNodeType.LOCK_RESOURCE,
				GraphNodeType.UNLOCK_RESOURCE,
				GraphNodeType.SUBFLOW_EXECUTE
			].includes(this.node.type)
		) {
			this.addPort(
				new DefaultPort(
					PortModelAlignment.RIGHT,
					this.node.type === GraphNodeType.PARALLEL_START
						? 'out-parallel'
						: 'out',
					onChange
				)
			)
		}

		if (
			[
				GraphNodeType.FINISH,
				GraphNodeType.EXECUTION_READ,
				GraphNodeType.EXECUTION_WRITE,
				GraphNodeType.PARALLEL_START,
				GraphNodeType.PARALLEL_MERGE,
				GraphNodeType.WAIT_FOR_EVENT,
				GraphNodeType.LOCK_RESOURCE,
				GraphNodeType.UNLOCK_RESOURCE,
				GraphNodeType.SUBFLOW_EXECUTE
			].includes(this.node.type)
		) {
			this.addPort(
				new DefaultPort(
					PortModelAlignment.LEFT,
					this.node.type === GraphNodeType.PARALLEL_MERGE
						? 'in-parallel'
						: 'in',
					onChange
				)
			)
		}
	}

	getIssueName(t: TranslationHelper) {
		switch (this.node.type) {
			case GraphNodeType.FINISH: {
				return t('FINISH_NODE')
			}

			case GraphNodeType.INIT: {
				return t('INIT_NODE')
			}

			case GraphNodeType.PARALLEL_START: {
				return t('PARALLEL_START')
			}

			case GraphNodeType.PARALLEL_MERGE: {
				return t('PARALLEL_MERGE')
			}

			case GraphNodeType.EXECUTION_READ: {
				return t('EXECUTE_READ_NODE')
			}

			case GraphNodeType.EXECUTION_WRITE: {
				return t('EXECUTE_WRITE_NODE')
			}

			case GraphNodeType.WAIT_FOR_EVENT: {
				return t('WAIT_FOR_EVENT')
			}

			case GraphNodeType.LOCK_RESOURCE: {
				return t('LOCK_RESOURCE')
			}

			case GraphNodeType.UNLOCK_RESOURCE: {
				return t('UNLOCK_RESOURCE')
			}

			case GraphNodeType.SUBFLOW_EXECUTE: {
				return t('SUBFLOW_EXECUTE')
			}
		}
	}

	validate(t: TranslationHelper): string[] {
		const errors = [] as string[]

		if (this.node.name === undefined || this.node.name === '') {
			errors.push(t('NAME_NOT_SET'))
		}

		switch (this.node.type) {
			case GraphNodeType.INIT: {
				switch (this.node.start) {
					case StartType.Interval: {
						if (
							this.node.hour === undefined ||
							this.node.minute === undefined
						) {
							errors.push(t('ERROR_NO_TIME'))
						}

						if (
							this.node.interval === StartInterval.Weekly &&
							this.node.weekday === undefined
						) {
							errors.push(t('ERROR_NO_WEEKDAY'))
						}

						if (
							this.node.interval === StartInterval.Monthly &&
							this.node.day === undefined
						) {
							errors.push(t('ERROR_NO_DAY'))
						}

						break
					}

					case StartType.WorkflowFinish: {
						if (!this.node.otherWorkflowCode) {
							errors.push(t('ERROR_NO_OTHER_WORKFLOW'))
						}

						break
					}

					case StartType.Cron: {
						if (!this.node.cron) {
							errors.push(t('ERROR_NO_CRON'))
						}

						break
					}

					case StartType.RunScript: {
						if (!this.node.script) {
							errors.push(t('ERROR_NO_SCRIPT'))
						}

						break
					}
				}

				break
			}

			case GraphNodeType.EXECUTION_WRITE: {
				switch (this.node.method) {
					case ExecutionMethod.CallMapping:

					case ExecutionMethod.RunMapping: {
						if (!this.node.mappingCode) {
							errors.push(t('NO_MAPPING_SELECTED'))
						}

						break
					}

					case ExecutionMethod.RunScript: {
						if (!this.node.script) {
							errors.push(t('ERROR_NO_SCRIPT'))
						}

						break
					}
				}

				break
			}

			case GraphNodeType.EXECUTION_READ: {
				if (!this.node.mappingCode) {
					errors.push(t('NO_MAPPING_SELECTED'))
				}

				break
			}
		}

		return errors
	}
}

export class MddNodeFactory extends AbstractReactFactory<
	MddWidgetModel,
	DiagramEngine
> {
	constructor() {
		super('mdd-widget')
	}

	generateReactWidget(event: GenerateWidgetEvent<MddWidgetModel>) {
		return <MddWidget engine={this.engine} size={50} node={event.model} />
	}

	generateModel() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return null as any
	}
}

export const engineFitNodes = (engine: DiagramEngine) => {
	const canvas = engine.getCanvas()
	const model = engine.getModel()

	const minX = Math.min(
		...Object.values(model.getNodes()).map(node => node.getX())
	)

	const minY = Math.min(
		...Object.values(model.getNodes()).map(node => node.getY())
	)

	const maxX = Math.max(
		...Object.values(model.getNodes()).map(
			node => node.getBoundingBox().getBottomRight().x
		)
	)

	const maxY = Math.max(
		...Object.values(model.getNodes()).map(
			node => node.getBoundingBox().getBottomRight().y
		)
	)

	const width = maxX - minX
	const height = maxY - minY

	const centerX = minX + width * 0.5
	const centerY = minY + height * 0.5

	const actualWidth = Math.max(
		width + canvas.clientWidth * 0.2,
		canvas.clientWidth * 0.75
	)

	const actualHeight = Math.max(
		height + canvas.clientHeight * 0.2,
		canvas.clientHeight * 0.75
	)

	const xFactor = canvas.clientWidth / actualWidth
	const yFactor = canvas.clientHeight / actualHeight
	const zoomFactor = xFactor < yFactor ? xFactor : yFactor

	model.setZoomLevel(zoomFactor * 100)

	model.setOffset(
		-(centerX * zoomFactor - actualWidth * 0.5 * xFactor),
		-(centerY * zoomFactor - actualHeight * 0.5 * yFactor)
	)

	engine.repaintCanvas()
}

export const nodeToNode = <T extends object>(node: MddWidgetModel): T =>
	({
		...node.node,
		id: node.node.id,
		x: node.getX(),
		y: node.getY()
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any)

export const nodesByType = <T extends object>(
	n: MddWidgetModel[],
	type: GraphNodeType
): T[] => n.filter(i => i.node.type === type).map(nodeToNode) as T[]
