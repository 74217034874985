import {
	ExecuteReadGraphNode,
	ExecuteWriteGraphNode,
	GraphNodeType,
	LockResourceGraphNode,
	ParallelMergeGraphNode,
	ParallelStartGraphNode,
	SubflowExecuteGraphNode,
	UnlockResourceGraphNode,
	WaitForEventGraphNode
} from '../types'

export const DEFAULT_VALUES: Record<GraphNodeType, Record<string, unknown>> = {
	[GraphNodeType.INIT]: {},
	[GraphNodeType.FINISH]: {},
	[GraphNodeType.EXECUTION_WRITE]: {
		onOffFlag: true,
		waitUntilFinishedFlag: true
	} as ExecuteWriteGraphNode,
	[GraphNodeType.EXECUTION_READ]: {
		onOffFlag: true,
		waitUntilFinishedFlag: true
	} as ExecuteReadGraphNode,
	[GraphNodeType.PARALLEL_MERGE]: {
		onOffFlag: true
	} as ParallelMergeGraphNode,
	[GraphNodeType.PARALLEL_START]: {
		onOffFlag: true
	} as ParallelStartGraphNode,
	[GraphNodeType.WAIT_FOR_EVENT]: {
		onOffFlag: true
	} as WaitForEventGraphNode,
	[GraphNodeType.LOCK_RESOURCE]: {
		onOffFlag: true
	} as LockResourceGraphNode,
	[GraphNodeType.UNLOCK_RESOURCE]: {
		onOffFlag: true
	} as UnlockResourceGraphNode,
	[GraphNodeType.SUBFLOW_EXECUTE]: {
		onOffFlag: true,
		waitUntilFinishedFlag: true
	} as SubflowExecuteGraphNode
}
