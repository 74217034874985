/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseEvent, CanvasWidget } from '@projectstorm/react-canvas-core'
import { DiagramEngine, DiagramModel } from '@projectstorm/react-diagrams'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { GraphNodeType } from '../types'
import { MddWidgetModel } from '../utils'
import { GRAPH_ITEM_TYPE_TRANSFER_KEY } from './Controls/components/NodeItem'
import { BaseEntityEvent } from '@projectstorm/react-canvas-core'
import { v4 as uuid4 } from 'uuid'
import { debounce } from 'debounce'
import { DEFAULT_VALUES } from '../utils/defaultValues'

export const Layer = ({
	engine,
	model,
	offset,
	zoom,
	onChange,
	onSelection,
	onZoom,
	onMove,
	systemNodeId,
	nodeId
}: {
	engine: DiagramEngine
	model: DiagramModel
	systemNodeId: number
	offset: { x: number; y: number }
	zoom: number
	onChange: () => void
	onSelection: (
		e: BaseEntityEvent<MddWidgetModel> & {
			isSelected: boolean
		}
	) => void
	onZoom: (zoom: number) => void
	onMove: (offset: { x: number; y: number }) => void
	nodeId: number
}) => {
	const [updateCount, setUpdateCount] = useState(0)

	const onZoomDebounce = useMemo(
		() => debounce((zoom: number) => onZoom(zoom / 100), 500),
		[]
	)

	const onMoveDebounce = useMemo(
		() =>
			debounce(
				(x: number, y: number) =>
					onMove({
						x,
						y
					}),
				500
			),
		[]
	)

	useEffect(() => {
		model.registerListener({
			eventDidFire: ((event: BaseEvent & { function: string }) => {
				if (event.function === 'zoomUpdated') {
					onZoomDebounce((event as any).zoom)
				}

				if (event.function === 'offsetUpdated') {
					onMoveDebounce((event as any).offsetX, (event as any).offsetY)
				}
			}) as any
		})
	}, [model])

	const refContainer = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const preventDefault = (e: WheelEvent) => e.preventDefault()
		const scrollEl = refContainer.current
		scrollEl?.addEventListener('wheel', preventDefault)

		return () => scrollEl?.removeEventListener('wheel', preventDefault)
	}, [])

	const gridSize = 60 * Math.pow(2, zoom < 0.5 ? (zoom < 0.25 ? 2 : 1) : 0)

	return (
		<GraphContainer
			ref={refContainer}
			onDrop={event => {
				const type = event.dataTransfer.getData(
					GRAPH_ITEM_TYPE_TRANSFER_KEY
				) as GraphNodeType

				if (!type) {
					return
				}

				const defaultValues = DEFAULT_VALUES[type]

				const node = new MddWidgetModel(
					systemNodeId,
					nodeId,
					{ type: type, x: 0, y: 0, id: uuid4(), ...defaultValues },
					onChange
				)

				const point = engine.getRelativeMousePoint(event)

				node.registerListener({
					positionChanged: onChange,
					selectionChanged: onSelection as any
				})

				node.setPosition(point)
				model.addNode(node)
				setUpdateCount(updateCount + 1)
			}}
			onDragOver={event => event.preventDefault()}
		>
			<BackgroundGrid
				style={{
					backgroundPosition: `${offset.x}px ${offset.y}px`,
					backgroundSize: `${zoom * gridSize}px ${zoom * gridSize}px`
				}}
			/>
			<CanvasWidget engine={engine} />
		</GraphContainer>
	)
}

const BackgroundGrid = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-size: 40px 40px;
	background-image: linear-gradient(to right, #eee 1px, transparent 1px),
		linear-gradient(to bottom, #eee 1px, transparent 1px);
`

const GraphContainer = styled.div`
	height: 100%;
	flex: 1;
	position: relative;

	> * {
		height: 100%;
		min-height: 100%;
		width: 100%;
	}
`
