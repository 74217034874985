import {
	DiagramEngine,
	DiagramModel,
	DiagramModelGenerics
} from '@projectstorm/react-diagrams-core'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { MddWidgetModel } from '../../utils'
import { GraphError } from '../../validator'
import { Issues } from './components/Issues'
import { NodeProperties } from './components/NodeProperties/NodeProperties'

type Props = {
	issues: GraphError[]
	nodeId: number
	systemNodeId: number
	onNodeFocus: (node: MddWidgetModel) => void
	selected: MddWidgetModel | undefined
	editMode: boolean
	engine: DiagramEngine
	model: DiagramModel<DiagramModelGenerics>
}

export const RightPanel = ({
	issues,
	systemNodeId,
	nodeId,
	onNodeFocus,
	selected,
	editMode,
	engine,
	model
}: Props) => {
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const stopPropagation = (e: KeyboardEvent) => e.stopPropagation()
		const refEl = ref.current
		refEl?.addEventListener('keydown', stopPropagation)
		refEl?.addEventListener('keyup', stopPropagation)

		return () => {
			refEl?.removeEventListener('keydown', stopPropagation)
			refEl?.removeEventListener('keyup', stopPropagation)
		}
	}, [])

	return (
		<C ref={ref}>
			<Issues issues={issues} onNodeFocus={onNodeFocus} />
			{selected && (
				<NodeProperties
					node={selected}
					nodeId={nodeId}
					systemId={systemNodeId}
					editMode={editMode}
					engine={engine}
					model={model}
				/>
			)}
		</C>
	)
}

const C = styled.div`
	position: absolute;
	right: 0px;
	width: 300px;
	max-height: 100%;
	overflow: auto;
`
