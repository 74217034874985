import React from 'react'
import styled from 'styled-components'
import { FormlessText } from '@/components/UberForm/Input/Text/Text'
import { FormValue } from '@/components/UberForm/Form'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

type Props = {
	value?: string
	onChange: (v: FormValue) => void
}

export const Search = ({ value, onChange }: Props) => {
	return (
		<C>
			<FormlessText
				compact
				value={value}
				onChange={onChange}
				inputIcon={faSearch}
				clearable
			/>
		</C>
	)
}

const C = styled.div``
