import React from 'react'
import styled from 'styled-components'
import { GraphNodeType } from '@/components/GraphEditor/types'
import { NodeIcon } from '../../MddWidget/components/NodeIcon'

export const GRAPH_ITEM_TYPE_TRANSFER_KEY = 'mdd-graph-item-type'
export const GRAPH_ITEM_MAPPING_TRANSFER_KEY = 'mdd-graph-item-mapping'

type Props = {
	type: GraphNodeType
	label: string
	mapping?: number
}

const NodeItemComponent = ({ type, label, mapping }: Props) => {
	return (
		<Item
			draggable={true}
			onDragStart={event => {
				event.dataTransfer.setData(GRAPH_ITEM_TYPE_TRANSFER_KEY, type)

				event.dataTransfer.setData(
					GRAPH_ITEM_MAPPING_TRANSFER_KEY,
					mapping?.toString() ?? ''
				)
			}}
		>
			{label}
			<div style={{ marginLeft: 'auto' }}>
				<NodeIcon nodeType={type} />
			</div>
		</Item>
	)
}

const Item = styled.div`
	display: flex;
	border: 1px solid #aaa;
	padding: 5px 10px;
	margin: 5px 0;
`

export const NodeItem = NodeItemComponent
