import {
	GraphNode,
	GraphNodeType,
	NodeCategory
} from '@/components/GraphEditor/types'
import { useAppContext } from '@/utils/hooks'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { NodeItem } from './NodeItem'
import { Search } from './Search'

type Props = {
	enableBasicNodes: boolean
	availableNodes: NodeCategory[]
	usedNodes: GraphNode[]
}

export const Items = ({
	availableNodes,
	enableBasicNodes,
	usedNodes
}: Props) => {
	const { t } = useAppContext()
	const [search, setSearch] = useState('')

	const extendedOptions = useMemo(() => {
		const extended = [] as NodeCategory[]

		if (enableBasicNodes) {
			extended.push({
				name: t('BASIC'),
				items: [
					...(!usedNodes.find(un => un.type === GraphNodeType.INIT)
						? [{ type: GraphNodeType.INIT, name: t('INIT_NODE') }]
						: []),
					...(!usedNodes.find(un => un.type === GraphNodeType.FINISH)
						? [{ type: GraphNodeType.FINISH, name: t('FINISH_NODE') }]
						: [])
				]
			})

			extended.push({
				name: t('PARALLEL'),
				items: [
					{
						type: GraphNodeType.PARALLEL_START,
						name: t('PARALLEL_START')
					},
					{
						type: GraphNodeType.PARALLEL_MERGE,
						name: t('PARALLEL_MERGE')
					}
				]
			})
		}

		return [...extended, ...availableNodes]
	}, [t, usedNodes, availableNodes, enableBasicNodes])

	const categories = useMemo(
		() =>
			extendedOptions
				.map(o => ({
					name: o.name,
					items:
						search && search.length > 0
							? o.items.filter(i =>
									i.name.toLowerCase().includes(search.toLowerCase())
							  )
							: o.items
				}))
				.filter(i => i.items.length > 0),
		[extendedOptions, search]
	)

	return (
		<Container>
			<Search value={search} onChange={v => setSearch(v as string)} />
			<Categories>
				{categories.map((c, i) => (
					<Category key={i}>
						<Title>{c.name}</Title>
						<List>
							{c.items.map((i, k) => (
								<NodeItem
									key={k}
									type={i.type}
									label={i.name}
									mapping={i.mapping}
								/>
							))}
						</List>
					</Category>
				))}
			</Categories>
		</Container>
	)
}

const Container = styled.div`
	margin-top: 0.5rem;
	flex: 1;
	min-height: 0;
	overflow: auto;

	display: flex;
	flex-direction: column;
`

const Category = styled.div``

const Categories = styled.div`
	flex: 1;
	min-height: 0;
	overflow: auto;
`

const Title = styled.div`
	text-transform: uppercase;
	margin-top: 1rem;
`

const List = styled.div``
