import {
	ExecutionMethod,
	GraphNode,
	GraphNodeType
} from '@/components/GraphEditor/types'
import {
	SelectFormField,
	TextAreaFormField,
	TextFormField
} from '@/components/UberForm'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { useAppContext, useAppStore } from '@/utils/hooks'
import React, { useMemo } from 'react'

type Props = {
	systemId: number
	node: GraphNode
}

export const ExecutionWriteProps = ({ node, systemId }: Props) => {
	const { t } = useAppContext()

	const execution =
		node.type === GraphNodeType.EXECUTION_WRITE ? node : undefined

	const executionMethod = execution?.method

	const mappings = useAppStore(state => state.system.mappings[systemId])

	const executionMethods = useMemo(
		() => [
			{
				value: ExecutionMethod.CallMapping,
				label: t('EXECUTE_WRITE_METHOD_CALL_MAPPING')
			},
			{
				value: ExecutionMethod.RunMapping,
				label: t('EXECUTE_WRITE_METHOD_RUN_MAPPING')
			},
			{
				value: ExecutionMethod.RunScript,
				label: t('EXECUTE_WRITE_METHOD_RUN_SCRIPT')
			}
		],
		[t]
	)

	return (
		<>
			<TextFormField title={t('INPUT_PARAMETER')} name="inputParameter" />
			<SelectFormField
				title={t('EXECUTE_WRITE_METHOD')}
				name="method"
				options={executionMethods}
			/>

			{(executionMethod === ExecutionMethod.CallMapping ||
				executionMethod === ExecutionMethod.RunMapping) && (
				<SelectFormField
					title={t('EXECUTE_WRITE_MAPPING')}
					name="mappingCode"
					valueKey="code"
					labelKey="name"
					options={mappings}
				/>
			)}

			{executionMethod === ExecutionMethod.RunScript && (
				<TextAreaFormField
					title={t('EXECUTE_WRITE_SCRIPT')}
					name="script"
					rows={20}
				/>
			)}
			<CheckboxFormField
				title={t('WAIT_UNTIL_FINISHED')}
				name="waitUntilFinishedFlag"
			/>
			<CheckboxFormField title={t('ON_OFF')} name="onOffFlag" />
		</>
	)
}
