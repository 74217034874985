import { getNodesOfSpecificParent } from '@/api'
import { useApi } from '@/api/hooks'
import { StructureDto } from '@/api/models'
import { GraphNode } from '@/components/GraphEditor/types'
import { SelectFormField, TextFormField } from '@/components/UberForm'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { useAppContext } from '@/utils/hooks'
import React, { useMemo } from 'react'

type Props = {
	node: GraphNode
	nodeId: number
}

export const ExecutionReadProps = ({ nodeId }: Props) => {
	const { t } = useAppContext()

	const childNodes = useApi(getNodesOfSpecificParent(nodeId))

	const mappings = useMemo(
		() =>
			childNodes.data?.filter(
				i => i.type === StructureDto.TypeEnum.MAPPING_READ
			),
		[childNodes.data]
	)

	return (
		<>
			<TextFormField title={t('INPUT_PARAMETER')} name="inputParameter" />
			<SelectFormField
				title={t('EXECUTE_READ_MAPPING')}
				name="mappingCode"
				valueKey="code"
				labelKey="name"
				options={mappings}
			/>
			<CheckboxFormField
				title={t('WAIT_UNTIL_FINISHED')}
				name="waitUntilFinishedFlag"
			/>
			<CheckboxFormField title={t('ON_OFF')} name="onOffFlag" />
		</>
	)
}
