import { useNodeData } from '@/components/GraphEditor/hooks/useNodeData'
import { GraphNode, GraphNodeType } from '@/components/GraphEditor/types'
import { MddWidgetModel } from '@/components/GraphEditor/utils'
import { nodeTypeToTitle } from '@/components/GraphEditor/utils/nodeTypeToTitle'
import { Form } from '@/components/UberForm'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { useAppContext, useAppStore } from '@/utils/hooks'
import {
	DiagramEngine,
	DiagramModel,
	DiagramModelGenerics
} from '@projectstorm/react-diagrams-core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { FormShared } from './FormShared'
import { ExecutionReadProps } from './types/ExecutionReadProps'
import { ExecutionWriteProps } from './types/ExecutionWriteProps'
import { FinishProps } from './types/FinishProps'
import { InitProps } from './types/InitProps'
import { LockResourceProps } from './types/LockResourceProps'
import { ParallelProps } from './types/ParallelProps'
import { SubflowExecuteProps } from './types/SubflowExecuteProps'
import { WaitForEventProps } from './types/WaitForEventProps'

type Props = {
	node: MddWidgetModel
	systemId: number
	nodeId: number
	editMode: boolean
	engine: DiagramEngine
	model: DiagramModel<DiagramModelGenerics>
}

export const NodeProperties = ({ node, systemId, nodeId, editMode }: Props) => {
	const { t } = useAppContext()
	const nodeDataInit = useNodeData(node.node)
	const [nodeData, setNodeData] = useState<GraphNode | undefined>(nodeDataInit)
	const formRef = useRef<Form<any>>(null)
	const mappings = useAppStore(state => state.system.mappings[systemId])

	useEffect(() => {
		setNodeData(nodeDataInit)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [node])

	const handleChange = useCallback(
		(v: any, source: FormlessFormField<any> | undefined) => {
			const newData = {
				...node.node,
				...v,
				...(source?.props.name === 'mappingCode'
					? {
							name: mappings?.find(mapping => mapping.code === v['mappingCode'])
								?.name
					  }
					: {})
			}

			formRef.current?.setValues(newData, false, true)
			node.node = newData
			node.onChange()

			setNodeData(newData)
		},
		[node, mappings]
	)

	const form = useMemo(() => {
		switch (nodeData?.type) {
			case GraphNodeType.INIT:
				return <InitProps node={nodeData} nodeId={nodeId} />

			case GraphNodeType.FINISH:
				return <FinishProps node={nodeData} />

			case GraphNodeType.EXECUTION_READ:
				return <ExecutionReadProps node={nodeData} nodeId={nodeId} />

			case GraphNodeType.EXECUTION_WRITE:
				return <ExecutionWriteProps node={nodeData} systemId={systemId} />

			case GraphNodeType.PARALLEL_MERGE:
			case GraphNodeType.PARALLEL_START:
				return <ParallelProps node={nodeData} />

			case GraphNodeType.LOCK_RESOURCE:
			case GraphNodeType.UNLOCK_RESOURCE:
				return <LockResourceProps node={nodeData} />

			case GraphNodeType.WAIT_FOR_EVENT:
				return <WaitForEventProps node={nodeData} />

			case GraphNodeType.SUBFLOW_EXECUTE:
				return <SubflowExecuteProps node={nodeData} systemId={systemId} />
		}
	}, [nodeData, systemId, handleChange])

	const nodeTypeTitle = useMemo(() => nodeTypeToTitle(node, t), [node, t])

	return form ? (
		<>
			<Title>
				<span>{t('PROPERTIES')}:</span>&nbsp;
				<div>{nodeTypeTitle}</div>
			</Title>
			<FormContainer key={nodeData?.id} onKeyDown={e => e.stopPropagation()}>
				<FormShared
					node={nodeData}
					onChange={handleChange}
					editMode={editMode}
					formRef={formRef}
				>
					{form}
				</FormShared>
			</FormContainer>
		</>
	) : (
		<></>
	)
}

const Title = styled.div`
	display: flex;
	background: rgba(240, 240, 240, 0.8);
	border-left: 1px solid #ccc;
	align-items: center;

	> span {
		padding: 11px 0 11px 8px;
	}
`

const FormContainer = styled.div`
	background: rgba(240, 240, 240, 0.8);
	padding: 3px 5px;

	input[type='text'],
	input[type='number'],
	.checkbox::before,
	textarea {
		background: #fff;
	}
`
