import { useAppContext } from '@/utils/hooks'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Validators } from '../UberForm'
import NumberFormField from '../UberForm/Helpers/NumberFormField'

type Props = {
	className?: string
	title?: string
	hourName: string
	minuteName: string
}

export const TimeField = ({
	className,
	title,
	hourName,
	minuteName
}: Props) => {
	const { t } = useAppContext()

	const validators = useMemo(
		() => ({
			hour: [Validators.numeric(true, 0, 23)],
			minute: [Validators.numeric(true, 0, 59)]
		}),
		[]
	)

	return (
		<C className={className}>
			<label>{title}</label>
			<Inputs>
				<NumberFormField
					name={hourName}
					placeholder={t('HH')}
					validators={validators.hour}
				/>
				<NumberFormField
					name={minuteName}
					placeholder={t('MM')}
					validators={validators.minute}
				/>
			</Inputs>
		</C>
	)
}

const C = styled.div``

const Inputs = styled.div`
	display: flex;

	> * {
		width: 50%;
	}
`
