import React from 'react'
import {
	DiagramEngine,
	PortWidget,
	PortModelAlignment
} from '@projectstorm/react-diagrams'
import styled, { css } from 'styled-components'
import { MddWidgetModel } from '../../utils'
import { InitWidget } from './components/InitWidget'
import { FinishWidget } from './components/FinishWidget'
import { ParallelStartWidget } from './components/ParallelStartWidget'
import { ParallelMergeWidget } from './components/ParallelMergeWidget'
import { GraphNodeType } from '../../types'
import { ExecutionWriteWidget } from './components/ExecutionWriteWidget'
import { ExecutionReadWidget } from './components/ExecutionReadWidget'
import { RectangleWidget } from './components/RectangleWidget'
import { useAppContext } from '@/utils/hooks'

type Props = {
	node: MddWidgetModel
	engine: DiagramEngine
	size?: number
}

const MddWidgetComponent = ({ engine, node, size = 32 }: Props) => {
	const { t } = useAppContext()
	const rightPort = node.getPort(PortModelAlignment.RIGHT)
	const leftPort = node.getPort(PortModelAlignment.LEFT)

	const ports = (
		<>
			{leftPort && (
				<PortWidget
					style={{
						left: '-8px',
						top: '50%',
						marginTop: node.isLocked() ? -4 : -8,
						position: 'absolute'
					}}
					port={leftPort}
					engine={engine}
				>
					<Port isSelected={node.isSelected()} isLocked={node.isLocked()} />
				</PortWidget>
			)}
			{rightPort && (
				<PortWidget
					style={{
						right: '-8px',
						top: '50%',
						marginTop: node.isLocked() ? -4 : -8,
						position: 'absolute'
					}}
					port={rightPort}
					engine={engine}
				>
					<Port isSelected={node.isSelected()} isLocked={node.isLocked()} />
				</PortWidget>
			)}
		</>
	)

	switch (node.node.type) {
		case GraphNodeType.INIT: {
			return <InitWidget node={node} size={size} ports={ports} />
		}

		case GraphNodeType.FINISH: {
			return <FinishWidget node={node} size={size} ports={ports} />
		}

		case GraphNodeType.EXECUTION_WRITE: {
			return <ExecutionWriteWidget node={node} ports={ports} />
		}

		case GraphNodeType.EXECUTION_READ: {
			return <ExecutionReadWidget node={node} ports={ports} />
		}

		case GraphNodeType.PARALLEL_START: {
			return <ParallelStartWidget node={node} ports={ports} />
		}

		case GraphNodeType.PARALLEL_MERGE: {
			return <ParallelMergeWidget node={node} ports={ports} />
		}

		case GraphNodeType.WAIT_FOR_EVENT:
		case GraphNodeType.LOCK_RESOURCE:
		case GraphNodeType.UNLOCK_RESOURCE:

		case GraphNodeType.SUBFLOW_EXECUTE: {
			return <RectangleWidget node={node} ports={ports} />
		}

		default: {
			return <></>
		}
	}
}

const Port = styled.div<{ isSelected: boolean; isLocked: boolean }>`
	width: 16px;
	height: 16px;
	z-index: 10;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 8px;
	cursor: pointer;
	&:hover {
		background: rgba(0, 0, 0, 1);
	}

	${props =>
		props.isSelected &&
		css`
			background: rgba(0, 192, 255, 0.5);

			&:hover {
				background: rgba(0, 192, 255, 1);
			}
		`}

	${props =>
		props.isLocked &&
		css`
			width: 8px;
			height: 8px;
			border-radius: 4px;
			background: rgba(0, 0, 0);
		`}
`

export const MddWidget = MddWidgetComponent
