import { GraphNode } from '@/components/GraphEditor/types'
import { TextAreaFormField } from '@/components/UberForm'
import { useAppContext } from '@/utils/hooks'
import React from 'react'

type Props = {
	node: GraphNode
}

export const FinishProps = ({}: Props) => {
	const { t } = useAppContext()

	return (
		<>
			<TextAreaFormField title={t('FINISH_SCRIPT')} name="script" rows={20} />
		</>
	)
}
