import React from 'react'
import { MddWidgetModel } from '../../../utils'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faExpandArrowsAlt,
	faLongArrowAltRight
} from '@fortawesome/free-solid-svg-icons'
import { NodeIcon } from './NodeIcon'
import { Title } from './Title'

export const ParallelStartWidget = ({
	node,
	ports
}: {
	node: MddWidgetModel
	ports: React.ReactNode
}) => {
	return (
		<ParallelStart selected={node.isSelected()}>
			<NodeIcon nodeType={node.node.type} />
			<Title node={node} />
			{ports}
		</ParallelStart>
	)
}

const ParallelStart = styled.div<{ selected: boolean }>`
	display: flex;
	align-items: center;
	min-width: 100px;
	padding: 10px;
	border: 1px solid #999;
	border-radius: 20px;
	text-align: center;

	${props => css`
		&:hover {
			padding: 9px;
			border-width: 2px;
			border-color: ${props.theme.colors.graph.selectedBorder};
		}
	`}

	${props =>
		props.selected &&
		css`
			padding: 9px;
			border-width: 2px;
			border-color: ${props.theme.colors.graph.selectedBorder};
		`}
`
