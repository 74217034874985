import React from 'react'
import styled, { css } from 'styled-components'
import { MddWidgetModel } from '../../../utils'
import { NodeIcon } from './NodeIcon'
import { Title } from './Title'

type Props = {
	node: MddWidgetModel
	ports: React.ReactNode
}

export const RectangleWidget = ({ node, ports }: Props) => {
	return (
		<Wrapper selected={node.isSelected()}>
			<NodeIcon nodeType={node.node.type} />
			<Title node={node} />
			{ports}
		</Wrapper>
	)
}

const Wrapper = styled.div<{ selected: boolean }>`
	display: flex;
	align-items: center;
	border: 1px solid #999;
	box-sizing: border-box;
	min-width: 200px;
	background: #fff;

	${props => css`
		&:hover {
			border-color: transparent;
			box-shadow: 0 0 0 2px ${props.theme.colors.graph.selectedBorder};
		}
	`}

	${props =>
		props.selected &&
		css`
			border-color: transparent;
			box-shadow: 0 0 0 2px ${props.theme.colors.graph.selectedBorder};
		`}
`
