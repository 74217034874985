import { useAppStore } from '@/utils/hooks'
import { useMemo } from 'react'
import { useGraphContext } from '../context/GraphContext'
import { GraphEditorData, GraphNode, GraphNodeType } from '../types'

const NODE_TYPE_TO_GRAPH_FORM_KEY: Record<
	GraphNodeType,
	keyof GraphEditorData
> = {
	[GraphNodeType.INIT]: 'initNode',
	[GraphNodeType.FINISH]: 'finishNode',
	[GraphNodeType.EXECUTION_WRITE]: 'executeWriteNodes',
	[GraphNodeType.EXECUTION_READ]: 'executeReadNodes',
	[GraphNodeType.PARALLEL_MERGE]: 'parallelMergeNodes',
	[GraphNodeType.PARALLEL_START]: 'parallelStartNodes',
	[GraphNodeType.WAIT_FOR_EVENT]: 'waitForEventNodes',
	[GraphNodeType.LOCK_RESOURCE]: 'lockResourceNodes',
	[GraphNodeType.UNLOCK_RESOURCE]: 'unlockResourceNodes',
	[GraphNodeType.SUBFLOW_EXECUTE]: 'subflowExecuteNodes'
}

/** Get node data from redux state */
export const useNodeData = (node: GraphNode) => {
	const { type, id } = node

	const graphFormKey = useMemo(() => NODE_TYPE_TO_GRAPH_FORM_KEY[type], [type])

	const { nodeId } = useGraphContext()

	const nodeData = useAppStore(state => {
		const nodes = state.workflow.workflows[nodeId]?.form.graph?.[
			graphFormKey
		] as any

		if (Array.isArray(nodes)) {
			return nodes?.find(node => node.id === id)
		}

		return nodes
	})

	return nodeData as GraphNode
}
